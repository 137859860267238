import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import "react-slideshow-image/dist/styles.css";
import { initPage } from "../../api/Api";
import Loading from "../Loading";
import { Cell, Grid, Row } from "../FoundationGrid";
import MainPageHeader from "../mainpage/MainPageHeader";
import MainPageSections from "../mainpage/MainPageSections";
import CallToAction from "../CallToAction";
import RevealFade from "react-reveal/Fade";
import MainPagePortfolioPreview from "../mainpage/MainPagePortfolioPreview";

const identifier = "digitalisation";

function DigitalisationPage({ locale }) {
  const [page, setPage] = useState({
    identifier: identifier,
    nav: "digitalisation",
    locale: locale,
    parts: { mainPageSections: [], mainPageSpecialSections: [] },
    ctas: { digitalisation: null },
  });

  const init = () => {
    initPage(page, (page) => {
      setPage({ ...page });
    });
  };

  useEffect(init, []);

  if (!page.data) return <Loading page={page} fullscreen />;

  return (
    <div className={`page main-page page-${page.identifier}`}>
      <MainLayout page={page}>
        <Grid>
          <Row>
            <Cell sm={24}>
              <MainPageHeader page={page} />
            </Cell>
          </Row>
        </Grid>
        <Grid full>
          <Row>
            <Cell sm={24}>
              <MainPageSections page={page} />
              <RevealFade bottom>
                <Grid style={{ marginTop: "60px" }}>
                  <Row>
                    <Cell sm={24}>
                      <CallToAction type="box" cta={page.ctas.digitalisation} />
                    </Cell>
                  </Row>
                </Grid>
              </RevealFade>
              <MainPagePortfolioPreview page={page} />
            </Cell>
          </Row>
        </Grid>
      </MainLayout>
    </div>
  );
}

export default DigitalisationPage;
